var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","nudge-bottom":"15"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"25"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.USER.url_image
            ? _vm.imageEndpoint + _vm.USER.url_image
            : '/images/defaults/avatar.png',"alt":"Username"}})])]}}])},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card',{staticClass:"text-center",attrs:{"flat":"","tile":"","dark":"","color":"secondary","width":"280","min-width":"180"}},[_c('div',{staticClass:"pa-2"},[_c('v-avatar',{attrs:{"size":"90"}},[_c('img',{attrs:{"src":_vm.USER.url_image
                ? _vm.imageEndpoint + _vm.USER.url_image
                : '/images/defaults/avatar.png',"alt":"Username"}})])],1),_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.USER ? _vm.USER.name : "User"))]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.USER ? _vm.USER.email : "Email@email.com")+" ")])])]),_c('v-card-actions',[_c('v-btn',{staticClass:"text-none font-weight-light",attrs:{"outlined":"","small":"","color":"#ddd"},on:{"click":_vm.profile}},[_c('span',{staticClass:"black--text"},[_vm._v(" Hồ sơ ")])]),_c('v-spacer'),_c('v-btn',{staticClass:"text-none font-weight-light",attrs:{"outlined":"","small":"","color":"#ddd"},on:{"click":_vm.logOut}},[_c('span',{staticClass:"black--text"},[_vm._v(" Đăng xuất ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }