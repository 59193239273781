<template>
  <v-menu
    width="250"
    offset-y
    bottom
    nudge-bottom="10"
    right
    close-delay="150"
    open-on-hover
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="mr-8 text-none" small depressed color="#0c76ff">
        Ứng dụng
      </v-btn>
    </template>
    <v-card flat max-height="500" tile width="250">
      <v-list>
        <v-list-item v-for="(item, i) in projects" :key="i"  @click="goToLink(item.path)">
          <BaseAvatar :data="item" class="mr-6" :size="30" />
          <v-list-item-content>
            <v-list-item-title
              v-text="item.name"
              class="text-truncate"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import BaseAvatar from "@/components/base-avatar.vue";
import { mapState } from "vuex";
export default {
  components: { BaseAvatar },
  computed: {
    ...mapState(["projects"]),
  },
  data: () => ({}),
  created() {},
  methods: {
    goToLink(link){
     window.location.href = link
    }
  },
};
</script>

<style></style>
