<template>
  <v-navigation-drawer
    app
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="drawerWidth"
    v-bind="bindDrawer"
    floating
    color="rgb(244, 245, 247)"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
  >
    <v-list-item
      :to="project.path"
      :class="{ 'px-2 pt-4 pb-6': !isMini, 'px-3 py-2': isMini }"
    >
      <BaseAvatar
        class="project-avatar mr-2"
        :data="project"
        :size="isMini ? 30 : 50"
      />
      <v-list-item-content>
        <div class="text-truncate project-title" v-text="project.name" />
      </v-list-item-content>
    </v-list-item>
    <v-list class="pa-0" :class="{ 'px-4': !isMini, 'px-2': isMini }" dense nav>
      <template v-for="(item, key) in menus.filter((el) => !el.hidden)">
        <MultiItemSidebar
          v-if="item.children && item.children.length > 0"
          :item="item"
          :mini="isMini"
          :key="key"
          :children="item.children.filter((el) => !el.hidden)"
        />
        <SingleItemSidebar v-else :item="item" :mini="isMini" :key="key" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import SingleItemSidebar from "./SingleItemSidebar.vue";
import MultiItemSidebar from "./MultiItemSidebar.vue";
import { mapActions, mapState } from "vuex";
import BaseAvatar from "@/components/base-avatar.vue";
import "perfect-scrollbar/css/perfect-scrollbar.css";
export default {
  components: { SingleItemSidebar, MultiItemSidebar, BaseAvatar },
  props: {
    source: String,
  },
  data() {
    return {
      drawerWidth: 0,
      mini: false,
      sidebarWidth: 250,
    };
  },

  created() {
    this.drawerWidth = this.sidebarWidth;
  },
  computed: {
    isMini() {
      return !this.$vuetify.breakpoint.mobile && !this.DRAWER_STATE;
    },
    ...mapState(["drawer", "project"]),
    menus() {
      return this.$store.state.routerRole.routes;
    },
    bindDrawer() {
      if (this.$vuetify.breakpoint.mobile) {
        return {
          temporary: true,
          bottom: true,
        };
      }
      return {
        permanent: true,
        clipped: true,
      };
    },
    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
      set(newValue) {
        if (newValue === this.drawer) return;
        this.TOGGLE_DRAWER();
      },
    },
  },
  methods: {
    ...mapActions(["TOGGLE_DRAWER"]),
  },
};
</script>
<style src="./Sidebar.scss" lang="scss" />
