<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :hide-default-footer="hideDefaultFooter"
      :loading-text="loadingText"
      :items-per-page="itemsPerPage"
      :page.sync="page"
    >
      <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
        <slot :name="name" v-bind="item"></slot>
      </template>
    </v-data-table>
    <div :class="classPaging" v-if="pageCount">
      <v-pagination
        v-model="current_page"
        :length="pageCount"
        @input="changePage"
        :total-visible="10"
        >></v-pagination
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    loading: Boolean,
    hideDefaultFooter: Boolean,
    loadingText: String,
    itemsPerPage: Number,
    page: {type:Number, default: 1},
    pageCount: Number,
    classPaging: String,
  },
  data: () => ({
    current_page: 1,
  }),
  computed: {
    slot() {
      return this.$scopedSlots;
    },
  },
  watch: {
    page(val) {
      this.current_page = val;
    },
  },
  methods: {
    changePage(e) {
      this.$emit("change-page", e);
    },
  },
};
</script>
<style scoped>
/deep/ .v-pagination button {
  box-shadow: none !important;
  outline: 0 !important;
}
/deep/ .v-data-table .v-data-table__wrapper {
  border-radius: 4px;
  border: 1px solid #bdc6cc !important;
}
/deep/ .v-data-table table th {
  color: #000 !important;
  font-size: 0.825rem !important;
}
/deep/.v-data-table table {
  border: none !important;
}
/deep/.v-data-table table th {
  background-color: #fff ;
}
</style>