import { project, projects } from "../config";

import User from "./modules/user";
import Vue from "vue";
import Vuex from "vuex";
import confirmBox from "./modules/confirmBox";
import routerRole from "./modules/routers";

Vue.use(Vuex);

export default new Vuex.Store({
  namespace: true,
  state: {
    drawer: true,
    project,
    projects,
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit("toggleDrawer");
    },
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    },
  },
  modules: {
    routerRole,
    User,
    confirmBox,
  },
});
