<template>
  <div class="dashboard-map-container" id="dashboard-map-container">
    <div class="dashboard-map" id="dashboard-map"></div>
  </div>
</template>

<script>
import "./L.Polyline.SnakeAnim";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getChamCongToday } from "@/api/chamcong";
import iconUser from "../../assets/images/iconUser.png";
import femaleUser from "../../assets/images/femaleAvatar.png"

export default {
  name: "tracking-map",
  data() {
    return {
      timeout: null,
      imageEndpoint: process.env.VUE_APP_BASE,
      femaleUser,
      iconUser,
      map: null,
      marker: null,
      search: null,
      layerGroup: [],
      polyline: [],
      router: null,
      dataAddresses: [],
      myIcon: null,
      poligon: null,
      chamCongs: [],
    };
  },
  watch: {
    search(val) {
      if (val != "" && val != null) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          document.getElementById("suggest").style.display = "table";
          document.getElementById("suggest").style.backgroundColor = "white";
          this.getLatLng(val);
        }, 500);
      } else {
        this.dataAddresses = [];
        document.getElementById("suggest").style.display = "none";
      }
    },
  },

  methods: {
    deleteMarker() {
      if (this.marker) {
        this.map.removeLayer(this.marker);
      }
    },
    async getChamCong() {
      let data = await getChamCongToday();
      this.chamCongs = data;
    },

    setMarker(item) {
      this.$nextTick(() => {
        document.getElementById("suggest").style.display = "none";
        this.deleteMarker();
        var lat = item.lat;
        var lng = item.long;
        if (lat && lng) {
          this.map.setView([lat, lng], 15);
          this.search = null;
          this.dataAddresses = [];
        } else {
          this.map.setView([21.17297206638078, 106.07179908027852], 12);
        }
      });
    },
    makeMarker(latlng) {
      this.marker = new L.Marker(latlng, {
        icon: this.myIcon,
        draggable: true,
      }).addTo(this.map);
    },
    getAddress(lat, lng) {
      this.$emit("get-address", {
        lat: lat,
        lng: lng,
      });
    },

    async getMap(lat = 21.005796351058695, lng = 105.8465337753296) {
      await this.getChamCong();
      this.map = L.map("dashboard-map").setView([lat, lng], 18);
      L.tileLayer("https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
        subdomains: ["a", "b", "c"],
        maxZoom: 20,
      }).addTo(this.map);

      this.myIcon = L.icon({
        iconUrl:
          "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
        iconSize: [35, 35],
      });
      let dataMaker = [];
      this.chamCongs.forEach((el) => {
        let gioiTinh = el.nhan_vien ? el.nhan_vien.gioi_tinh : true;
        let marker = L.icon({
          iconUrl: gioiTinh ? this.iconUser : this.femaleUser,
          iconSize: [35, 35],
        });
        let viTri = el.lat_check_out
          ? [el.lat_check_out, el.long_check_out]
          : [el.lat_check_in, el.long_check_in];
        let image = el.url_image_check_out
          ? el.url_image_check_out
          : el.url_image_check_in;
        let title = el.url_image_check_out ? "Check out" : "Check in";
        let time = el.url_image_check_out
          ? this.formatTime(el.time_check_in)
          : this.formatTime(el.time_check_in);

        dataMaker.push(L.marker(viTri));
        L.marker(viTri, { icon: marker }).addTo(this.map).bindPopup(`
          <div style='height: 360px; width: 250px; text-align: center'>
            <div style='font-size: 14px; font-weight: bold; margin-bottom: 5px'>${el.user.name} (${title})</div>
            <div  style='font-size: 14px; font-weight: bold; margin-bottom: 15px'>Thời gian: ${time}</div>
            <div>${el.dia_diem_check_out ? el.dia_diem_check_out.name : el.dia_diem.name}</div>
            <img src="${
              this.imageEndpoint + image
            }" height='280px' style='max-width: 230px'/>
            </div>
          `);
      });
      if (dataMaker && dataMaker.length) {
        var group = L.featureGroup(dataMaker);
        this.map.fitBounds(group.getBounds());
      }

      document
        .getElementsByClassName("leaflet-control-attribution")[0]
        .firstChild.remove();
      var a = document.createElement("a");
      a.href = "http://skymapglobal.vn/";
      a.text = "Skymap";
      document
        .getElementsByClassName("leaflet-control-attribution")[0]
        .append(a);
    },
    formatTime(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let gio =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let phut =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return gio + "h " + phut;
      } catch (error) {
        return "";
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getMap();
    });
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },
};
</script>

<style scoped>
.dashboard-map-container {
  min-width: 0;
  min-height: 0;
  flex: 1;
}

.dashboard-map {
  width: 100%;
  height: 450px;
  z-index: 2;
}

@media (max-width: 990px) {
  #boxsearch {
    width: 300px;
  }
}
@media (max-width: 768px) {
  #boxsearch {
    width: 260px;
  }
}
@media (max-width: 576px) {
  #boxsearch {
    width: 160px;
  }
}
</style>
