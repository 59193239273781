import "font-awesome/css/font-awesome.css";

import Vue from "vue";
import Vuetify from "vuetify/lib";
import config from "@/config";
import vi from "./vi";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "sm",
    scrollBarWidth: 16,
    thresholds: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: config.light,
    },
  },
  lang: {
    locales: { vi },
    current: "vi",
  },
});
