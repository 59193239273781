<template>
  <v-menu width="480" offset-y bottom nudge-bottom="10" left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="messageBadge ? (messageBadge = !messageBadge) : ''"
        v-bind="attrs"
        v-on="on"
        icon
        dark
        small
        class="mr-4"
      >
        <v-badge
          :value="soDangKyNghi"
          color="pink"
          :content="soDangKyNghi"
          overlap
        >
          <v-icon small>mdi-calendar</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card flat max-height="500" min-height="200" tile>
      <v-layout class="px-4 pt-3 pb-4" align-center style="width: 460px">
        <div class="text-h5 grey--text text--darken-3">
          Đăng ký nghỉ cần duyệt
        </div>
        <v-spacer></v-spacer>
        <router-link
          class="pr-4"
          style="font-size: 14px"
          :to="'/chamcong/xinnghi'"
          v-if="dangKyNghis && dangKyNghis.length > 0"
          >Xem tất cả</router-link
        >
      </v-layout>

      <div v-if="dangKyNghis && dangKyNghis.length > 0">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in dangKyNghis"
            :key="i"
            two-line
            @click="xemLichNghi"
          >
            <v-list-item-avatar color="indigo" size="40">
              <img
                v-if="item.nguoi_dang_ky && item.nguoi_dang_ky.url_image"
                :src="imageEndpoint + item.nguoi_dang_ky.url_image"
                alt="ManhLe"
              />
              <span
                style="color: white"
                v-else-if="item.nguoi_dang_ky && !item.nguoi_dang_ky.url_image"
                >{{ item.nguoi_dang_ky.name.charAt(0).toUpperCase() }}</span
              >
              <v-icon v-else dark>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content style="width: 250px" class="pr-2">
              <div style="font-size: 15px">
                <b>{{ item.nguoi_dang_ky.name }}</b> Đã đăng ký xin nghỉ
              </div>
              <v-list-item-subtitle class="text-truncate pt-2"
                >{{ formatDate(item.bat_dau) }} đến
                {{ formatDate(item.ket_thuc) }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>

      <v-layout v-else column align-center justify-center>
        <v-btn icon color="pink">
          <v-icon style="font-size: 30px">mdi-calendar</v-icon>
        </v-btn>
        <div class="pt-2" style="font-size: 16px">Không có đăng ký nghỉ</div>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
import { thongBaoNghi } from "@/api/thongbao";
export default {
  computed: {
    imageEndpoint() {
      return process.env.VUE_APP_BASE;
    },
  },
  data: () => ({
    dangKyNghis: [],
    notificationsBadge: true,
    soDangKyNghi: 0,
    messageBadge: true,
  }),
  created() {
    this.getThongBaoNghi();
  },
  methods: {
    async getThongBaoNghi() {
      let data = await thongBaoNghi();
      this.dangKyNghis = data ? data : [];
      this.soDangKyNghi = data ? data.length : 0;
    },
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let gio =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let phut =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return (
          gio +
          "h " +
          phut +
          " Ngày " +
          ngay +
          "/" +
          thang +
          "/" +
          date.getFullYear()
        );
      } catch (error) {
        return "";
      }
    },
    xemLichNghi() {
      if ("Đăng ký nghỉ" != this.$route.name) {
        this.$router.push("/chamcong/xinnghi");
      }
      return;
    },
  },
};
</script>

<style></style>
