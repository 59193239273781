<template>
  <v-menu width="400" offset-y bottom nudge-bottom="10" left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="messageBadge ? (messageBadge = !messageBadge) : ''"
        v-bind="attrs"
        v-on="on"
        icon
        class="mr-8"
        small
      >
        <v-badge :value="soThongBao" color="pink" :content="soThongBao" overlap>
          <v-icon color="yellow lighten-4" small>
            mdi-bell
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card flat max-height="500" min-height="200" tile>
      <v-layout class="px-4 pt-3 pb-4" align-center style="width: 390px">
        <div class="text-h5 grey--text text--darken-3">Thông báo</div>
        <v-spacer></v-spacer>
        <router-link
          class="pr-4"
          style="font-size: 14px"
          :to="'/thongbao'"
          v-if="thongBaos && thongBaos.length > 0"
          >Xem tất cả</router-link
        >
      </v-layout>

      <div v-if="thongBaos && thongBaos.length > 0">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in thongBaos"
            :key="i"
            two-line
            @click="xemThongBao(item)"
            :style="{ background: !item.seen ? '#E3F2FD' : 'white' }"
          >
            <v-list-item-avatar color="indigo" size="40">
              <img
                v-if="item.from_user && item.from_user.url_image"
                :src="imageEndpoint + item.from_user.url_image"
                alt="ManhLe"
              />
              <span
                style="color: white"
                v-else-if="item.from_user && !item.from_user.url_image"
                >{{ item.from_user.name.charAt(0).toUpperCase() }}</span
              >
              <v-icon v-else dark>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content style="width: 190px" class="pr-2">
              <div style="font-size: 15px">
                {{
                  item.noi_dung.length > 45
                    ? String(item.noi_dung).substr(0, 45) + "..."
                    : item.noi_dung
                }}
              </div>
              <v-list-item-subtitle
                v-text="item.thoi_gian_nhan_thong_bao"
                class="text-truncate"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>
      <v-layout v-else column align-center justify-center>
        <v-btn icon color="pink">
          <v-icon style="font-size: 30px">mdi-bell</v-icon>
        </v-btn>
        <div class="pt-2" style="font-size: 16px">Bạn chưa có thông báo</div>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
import { getThongBao, docThongBao } from "@/api/thongbao";
export default {
  computed: {
    imageEndpoint() {
      return process.env.VUE_APP_BASE;
    },
  },
  data: () => ({
    thongBaos: [],
    soThongBao: 0,
    messageBadge: true,
  }),
  created() {
    this.getDataThongBao();
  },
  methods: {
    async getDataThongBao() {
      this.loading = true;
      let data = await getThongBao({
        perPage: 50,
      });
      this.thongBaos = data.data;
      this.soThongBao = 0;
      this.thongBaos.forEach((el) => {
        if (!el.seen) {
          this.soThongBao++;
        }
      });
    },
    async docThongBao(id) {
      await docThongBao({ thong_bao_id: id });
    },
    async xemThongBao(item) {
      if (!item.seen) {
        await this.docThongBao(item.id);
        await this.getDataThongBao();
      }
      if (item.message_id != this.$route.params.id) {
        this.$router.push("/truyenthong/hopthu/" + item.message_id);
      } else return;
    },
  },
};
</script>

<style></style>
