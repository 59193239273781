<template>
  <v-app class="pa-6">
    <Header />
    <Sidebar />
    <v-main class="content">
      <router-view />
      <div style="position: fixed; z-index: 4; bottom: 5px; right: 10px">
        <mail-box></mail-box>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/Header/Header";
import Sidebar from "@/components/Sidebar/Sidebar";
import Footer from "@/components/Footer/Footer";
import MailBox from "@/components/MailBox/MailBox.vue";

import "./Layout.scss";

export default {
  name: "Layout",
  components: { Footer, Header, Sidebar, MailBox },
};
</script>

<style src="./Layout.scss" lang="scss" />
