<template>
  <v-menu offset-y bottom left nudge-bottom="15">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar v-bind="attrs" v-on="on" size="25">
        <img
          :src="
            USER.url_image
              ? imageEndpoint + USER.url_image
              : '/images/defaults/avatar.png'
          "
          alt="Username"
        />
      </v-avatar>
    </template>
    <v-card flat tile>
      <v-card
        flat
        tile
        dark
        color="secondary"
        width="280"
        min-width="180"
        class="text-center"
      >
        <div class="pa-2">
          <v-avatar size="90">
            <img
              :src="
                USER.url_image
                  ? imageEndpoint + USER.url_image
                  : '/images/defaults/avatar.png'
              "
              alt="Username"
            />
          </v-avatar>
        </div>
        <div class="py-2">
          <div class="text-caption">{{ USER ? USER.name : "User" }}</div>
          <div class="text-caption">
            {{ USER ? USER.email : "Email@email.com" }}
          </div>
        </div>
      </v-card>
      <v-card-actions>
        <v-btn
          class="text-none font-weight-light"
          outlined
          small
          @click="profile"
          color="#ddd"
        >
          <span class="black--text"> Hồ sơ </span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none font-weight-light"
          outlined
          small
          @click="logOut"
          color="#ddd"
        >
          <span class="black--text">
            Đăng xuất
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import store from "../../store/index";
export default {
  computed: {
    USER() {
      return this.$store.state.User.me;
    },
    imageEndpoint() {
      return process.env.VUE_APP_BASE;
    },
  },
  methods: {
    profile() {
      if (this.$route.name !== "Thông tin") {
        this.$router.push("/users/profile");
      }
      return;
    },
    logOut: async () => {
      try {
        await store.dispatch("User/logout");
        location.assign("/login");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
