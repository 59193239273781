<template>
  <v-card outlined class="style_card">
    <div class="d-flex px-1 pt-2 pb-1">
      <v-list-item style="height: 60px">
        <v-list-item-content>
          <v-list-item-title class="headline mb-1 d-flex"
            ><v-icon size="40">mdi-account-multiple</v-icon>
            <div class="d-flex align-center">
              {{ title }}

              <slot name="add" v-if="useAdd">
                <div style="height: 37px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="mx-2"
                        width="25px"
                        height="25px"
                        fab
                        small
                        depressed
                        size
                        color="var(--primary-color)"
                        @click="$emit('add')"
                      >
                        <v-icon size="18px" color="#fff"> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ titleAdd }}</span>
                  </v-tooltip>
                </div>
              </slot>
            </div>
          </v-list-item-title>
          <v-list-item-subtitle>{{ subTitle }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-title class="py-0 pr-0 ml-auto" v-if="!noFilter">
        <div :style="`width:${widthFilter}`" class="mr-4">
          <slot name="filter"></slot>
        </div>
      </v-card-title>
    </div>
    <div class="mt-2 mb-5 separation px-5"></div>
    <div class="px-5">
      <slot name="main_content"> </slot>
      <div v-show="isShow" class="flex-grow-0 siderbar">
        <v-card width="256" height="100%" class="d-flex flex-column" flat>
          <v-toolbar class="flex-grow-0" dense flat color="secondary_bg2">
            <v-toolbar-title class="text-capitalize">
              <slot name="title-siderbar"></slot>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <base-button icon tooltip="Làm mới">
              <v-icon>mdi-refresh</v-icon>
            </base-button>
            <base-button @click="isShow = false" icon>
              <v-icon>close</v-icon>
            </base-button>
          </v-toolbar>
          <div class="overflow-auto fill-height">
            <slot name="filter_content"> </slot>
          </div>
        </v-card>
      </div>
    </div>
    <slot />
  </v-card>
</template>
<script>
export default {
  props: {
    icon: String,
    title: String,
    subTitle: String,
    noFilter: Boolean,
    widthFilter: { type: String, default: "800px" },
    useAdd: Boolean,
    titleAdd: { type: String, default: "Thêm mới" },
  },
  computed: {
    slotTable() {
      return Object.keys(this.$scopedSlots)
        .filter((x) => x.includes("table."))
        .map((x) => x.replace("table.", ""));
    },
  },
  data: () => ({
    isShow: false,
  }),
};
</script>
<style lang="scss" scoped>
.style_card {
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.separation {
  width: 100%;
  position: relative;
  height: 1px;
  &::before {
    background-color: #cecece;
    content: "";
    height: 2px;
    width: 100%;
    display: block;
  }
}
.siderbar {
  > .v-card {
    background: #eceff1;
  }
  .siderbar--reset__filter {
    header {
      width: 20px;
    }
  }
}
</style>
<style>
.v-main__wrap {
  position: relative;
}
</style>
