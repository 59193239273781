<template>
  <v-menu
    right
    offset-x
    v-if="mini"
    open-on-hover
    :rounded="false"
    nudge-width="200px"
    close-on-content-click
    close-delay="50"
    nudge-right="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" link inactive :ripple="false" class="sidebar-item">
        <v-list-item-icon>
          <v-icon v-bind="attrs" v-text="item.icon" />
        </v-list-item-icon>
        <v-list-item-content>
          <div class="text-body-2 text-truncate" v-text="item.name" />
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card width="200px" tile>
      <v-list-item dense class="secondary" dark>
        <v-list-item-content>
          <div class="text-body-2 text-truncate" v-text="item.name" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="subItem in children"
        :key="subItem.name"
        :to="subItem.path"
        :title="subItem.name"
        class="sidebar-item"
      >
        <v-list-item-icon class="mr-2">
          <v-icon size="20" v-text="subItem.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <div class="text-body-2 text-truncate" v-text="subItem.name" />
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-menu>
  <v-list-group
    no-action
    :to="item.path"
    v-else
    color="secondary"
    class="sidebar-item"
  >
    <template v-slot:prependIcon>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" v-text="item.icon" />
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:activator>
      <v-list-item-content>
        <div class="text-body-2 text-truncate" v-text="item.name" />
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="subItem in children"
      :key="subItem.name"
      :to="subItem.path"
      :title="subItem.name"
      class="pl-8 sidebar-item"
      dense
    >
      <v-list-item-icon>
        <v-icon size="20" v-text="subItem.icon"></v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="text-body-2 text-truncate" v-text="subItem.name" />
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  props: {
    mini: Boolean,
    item: { required: true, type: Object },
    children: { required: true, type: Array },
  },
};
</script>

<style></style>
