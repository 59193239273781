<template>
  <v-app-bar
    dense
    app
    clipped-left
    class="main-header"
    color="secondary"
    dark
    height="42px"
    elevation="0"
  >
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
      style="position: fixed; bottom: 0; left: 0; width: 100%"
      height="4"
      v-show="loadingBar"
    ></v-progress-linear>
    <template v-if="!isMobile">
      <v-toolbar-title
        v-if="DRAWER_STATE"
        style="width:242px"
        class="d-flex align-center justify-center font-weight-bold ml-n2"
      >
        <img
          src="/logo/erp-sky-software.png"
          alt="full-logo"
          max-width="200"
          min-width="200"
          width="200"
        />
      </v-toolbar-title>
      <div
        v-else
        class="d-flex align-center justify-center ml-n3"
        style="width:52px"
      >
        <v-img
          max-width="30"
          aspect-ratio="1"
          src="/logo/erp-sky-software-icon.png"
          alt="full-logo"
        />
      </div>
    </template>
    <v-btn icon @click.stop="TOGGLE_DRAWER" small>
      <v-icon small>mdi-menu</v-icon>
    </v-btn>
    <MenuProject />
    <template v-if="isMobile">
      <v-toolbar-title class="text-center pr-4 font-weight-bold">
        Quản Lý Nhân Sự
      </v-toolbar-title>
    </template>
    <v-spacer></v-spacer>
    <MenuThonbaoNghi />
    <MenuNoti />
    <MenuProfile />
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from "vuex";
import config from "../../config";
import MenuProfile from "./menu-profile.vue";
import MenuNoti from "./menu-noti.vue";
import MenuThonbaoNghi from "./menu-thongbaonghi.vue";
import MenuProject from "./menu-project.vue";
export default {
  name: "Header",
  components: { MenuProfile, MenuNoti, MenuThonbaoNghi, MenuProject },
  data: () => ({
    config,
    imageEndpoint: process.env.VUE_APP_BASE,
    searchCollapse: true,
  }),
  computed: {
    ...mapState(["drawer"]),
    loadingBar() {
      return this.$store.state.routerRole.loading;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
    },
  },
  methods: {
    ...mapActions(["TOGGLE_DRAWER"]),
  },
};
</script>
