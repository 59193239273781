<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-layout
        class="align-center mt-3 mb-6 pl-1 pb-2"
        style="border-bottom: 1px solid gray"
      >
        <img :src="dashboardLogo" height="80px" />
        <div class="pl-4" style="font-size: 26px; font-weight: bold">
          THÔNG TIN HỆ THỐNG
        </div>
      </v-layout>
      <v-row>
        <v-col lg="6" sm="8" cols="12">
          <v-layout column>
            <v-card color="#303F9F" dark height="250">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div class="mr-6">
                  <v-card-title class="headline pt-6"
                    ><span style="font-size: 30px"
                      >Hello {{ USER.name }}!</span
                    ></v-card-title
                  >
                  <v-card-subtitle class="mt-1"
                    >Một ngày làm việc tốt đẹp!</v-card-subtitle
                  >

                  <v-card-actions>
                    <router-link :to="'/chamcong/danhsach'">
                      <v-btn class="ml-2 mt-5 mb-3" outlined rounded small>
                        Theo Dõi Chấm Công
                      </v-btn>
                    </router-link>
                  </v-card-actions>
                </div>
                <v-img
                  :src="pic[picRandom]"
                  aspect-ratio="2.5"
                  class="mt-8"
                ></v-img>
              </div>
            </v-card>
            <br />
            <v-row>
              <v-col lg="12" sm="12" md="12" cols="12">
                <v-card class="mx-1 mb-1" style="height: 260px" elevation="2">
                  <v-card-title class="pa-6 pb-3">
                    <p>Chấm công hôm nay</p>
                    <v-spacer></v-spacer>
                    <v-menu>
                      <v-list>
                        <v-list-item
                          v-for="(item, i) in mock.menu"
                          :key="i"
                          @click="() => {}"
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-text class="pa-6 pt-0">
                    <v-row no-gutters class="pb-5">
                      <v-col cols="6" class="my-auto">
                        <span
                          class="font-weight-medium card-dark-grey"
                          style="font-size: 20px"
                          >{{ checkingCount.checkIn }}
                          <span style="font-size: 16px; color: #00695c"
                            >CheckIn</span
                          >
                          - {{ checkingCount.checkOut }}
                          <span style="font-size: 16px; color: #c62828"
                            >CheckOut</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="5">
                        <Trend
                          :data="getRandomDataForTrends()"
                          :gradient="mock.trend.gradient"
                          :height="40"
                          stroke-width="4"
                          smooth
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="justify-space-between pb-3">
                      <v-col cols="5">
                        <div class="card-light-grey">Tổng chấm công</div>
                        <div class="text-h6 card-dark-grey font-weight-regular">
                          {{ checkingCount.checking }}
                          <span style="font-size: 13px">Lượt/</span>
                          {{
                            checkingCount.tongNhanVien -
                            checkingCount.notCheckIn
                          }}
                          <span style="font-size: 13px">Người</span>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <div class="card-light-grey">Chưa chấm công</div>
                        <div class="text-h6 card-dark-grey font-weight-regular">
                          {{ checkingCount.notCheckIn }}
                          <span style="font-size: 13px">Người</span>
                        </div>
                      </v-col>
                      <v-col cols="4" xl="2">
                        <div class="text-right card-light-grey">
                          Tỷ lệ chấm công
                        </div>
                        <div
                          class="
                            text-right text-h6
                            card-dark-grey
                            font-weight-regular
                          "
                        >
                          {{
                            Math.round(
                              (10000 *
                                (checkingCount.tongNhanVien -
                                  checkingCount.notCheckIn)) /
                                checkingCount.tongNhanVien
                            ) / 100
                          }}
                          %
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- <v-col lg="6" sm="12" md="7" cols="12">
                <v-card class="mx-1 mb-1">
                  <v-card-title class="pa-6 pb-3">
                    <p>Năng suất làm việc</p>
                    <v-spacer></v-spacer>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="textColor">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, i) in mock.menu"
                          :key="i"
                          @click="() => {}"
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-text class="pa-6 pt-0">
                    <v-row no-gutters class="pb-5">
                      <div class="mr-4">
                        <v-icon color="primary" class="ml-n2">
                          mdi-circle-medium
                        </v-icon>
                        <span class="card-light-grey">Phòng Nhân Sự</span>
                      </div>
                      <div>
                        <v-icon color="warning"> mdi-circle-medium </v-icon>
                        <span class="card-light-grey">Phòng Sản Xuất</span>
                      </div>
                    </v-row>
                    <v-row no-gutters class="pb-3">
                      <v-col>
                        <div
                          class="text-h6 card-light-grey font-weight-regular"
                        >
                          Phòng Nhân Sự
                        </div>
                        <v-progress-linear
                          :value="value"
                          background-color="#ececec"
                          color="primary"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pb-1">
                      <v-col>
                        <div
                          class="text-h6 card-light-grey font-weight-regular"
                        >
                          Phòng Sản Xuất
                        </div>
                        <v-progress-linear
                          :value="value2"
                          background-color="#ececec"
                          color="warning"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col> -->
            </v-row>
          </v-layout>
        </v-col>
        <v-col lg="6" sm="12" md="7" cols="12">
          <v-card elevation="2">
            <v-card-title>Chấm công hôm nay</v-card-title>
            <v-card-text>
              <tracking-map
                ref="map"
                @get-address="(addr) => handleMap(addr)"
              ></tracking-map>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-gutters>
                <v-col
                  cols="7"
                  sm="4"
                  md="4"
                  lg="5"
                  class="d-flex align-center"
                >
                  <p>Chi tiết truy cập hệ thống</p>
                </v-col>
                <v-col
                  sm="6"
                  md="6"
                  lg="5"
                  class="d-none d-sm-flex align-center"
                >
                  <v-icon size="18" color="warning">mdi-circle-medium</v-icon
                  ><span
                    class="card-dark-grey font-weight-regular mr-3"
                    style="font-size: 18px"
                    >Tablet</span
                  >
                  <v-icon size="18" color="primary">mdi-circle-medium</v-icon
                  ><span
                    class="card-dark-grey font-weight-regular mr-3"
                    style="font-size: 18px"
                    >Mobile</span
                  >
                  <v-icon size="18" color="#B1BCFF">mdi-circle-medium</v-icon
                  ><span
                    class="card-dark-grey font-weight-regular"
                    style="font-size: 18px"
                    >Desktop</span
                  >
                </v-col>
                <v-col cols="5" sm="2" md="2" lg="1" offset-lg="1">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-select
                        class="main-chart-select"
                        v-model="mainApexAreaSelect"
                        v-bind="attrs"
                        v-on="on"
                        dense
                        flat
                        single-line
                        hide-details
                        :items="mock.select"
                        outlined
                      ></v-select>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-6">
              <v-row>
                <v-col>
                  <ApexChart
                    v-if="apexLoading"
                    height="350"
                    type="area"
                    :options="mock.mainApexArea.options"
                    :series="
                      mainApexAreaSelect === 'Daily'
                        ? mock.mainApexArea.series
                        : mainApexAreaSelect === 'Weekly'
                        ? mock.mainApexArea.series2
                        : mock.mainApexArea.series3
                    "
                  ></ApexChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col> -->
        <v-col lg="4" sm="6" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-5">
              <p>Nhân sự</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters>
                <v-col cols="6" class="my-auto">
                  <span class="" style="font-size: 42px"
                    >{{ tongNhanSu }}
                    <span class="caption indigo--text">Người</span>
                  </span>
                  <div class="pt-4">
                    <div>
                      <span class="" style="font-size: 28px"
                        >{{ hoatDong }}
                        <span class="caption green--text">Đang hoạt động</span>
                      </span>
                    </div>
                    <div class="mt-2">
                      <span class="" style="font-size: 28px"
                        >{{ nghiViec }}
                        <span class="caption pink--text">Đã nghỉ việc</span>
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <img :src="NhanVienPic" style="max-width: 200px" />
                </v-col>
              </v-row>
              <v-layout column class="mt-4 so-nhan-su" style="height: 160px">
                <div
                  style="font-size: 15px; color: black"
                  class="pb-1"
                  v-for="(item, index) in phongBans"
                  :key="index"
                >
                  <v-icon class="pr-3" color="indigo">mdi-home</v-icon
                  ><span style="color: #3f51b5; font-weight: bold">{{
                    item.name
                  }}</span
                  >: <b>{{ item.nhan_su }} Người </b>
                  <span style="font-size: 13px"
                    >({{ "Nam: " + item.nam + ", Nữ: " + item.nu }})</span
                  >
                </div>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          :lg="showFullTyTrong ? 8 : 4"
          :sm="showFullTyTrong ? 12 : 6"
          cols="12"
        >
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p style="color: black">Tỷ trọng nhân sự</p>
              <v-spacer></v-spacer>
              <v-select
                style="width: 40%"
                class="main-chart-select"
                v-model="tyTrongFilter"
                dense
                flat
                single-line
                hide-details
                item-text="name"
                item-value="id"
                :items="[
                  { id: 1, name: 'Độ tuổi' },
                  { id: 2, name: 'Phòng ban' },
                  { id: 3, name: 'Giới tính' },
                ]"
                outlined
              ></v-select>
              <v-btn
                class="ml-2"
                fab
                dark
                x-small
                color="primary"
                @click="showFullTyTrong = true"
                v-if="!showFullTyTrong"
              >
                <v-icon dark> mdi-menu-right </v-icon>
              </v-btn>
              <v-btn
                v-else
                class="ml-2"
                fab
                dark
                x-small
                color="primary"
                @click="showFullTyTrong = false"
              >
                <v-icon dark> mdi-menu-left </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <div v-if="tyTrongFilter == 1">
                <ApexChart
                  height="300"
                  type="pie"
                  :options="optionsDoTuoi"
                  :series="seriesDoTuoi"
                ></ApexChart>
              </div>
              <div v-if="tyTrongFilter == 2">
                <ApexChart
                  height="300"
                  type="donut"
                  :options="options"
                  :series="series"
                ></ApexChart>
              </div>
              <div v-if="tyTrongFilter == 3">
                <ApexChart
                  height="300"
                  type="pie"
                  :options="optionsGioiTinh"
                  :series="seriesGioiTinh"
                ></ApexChart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          :lg="!showFullTyTrong ? 4 : 0"
          :sm="!showFullTyTrong ? 0 : 6"
          cols="12"
          v-if="!showFullTyTrong"
        >
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Truyền thông</p>
              <v-spacer></v-spacer>
              <router-link :to="'/truyenthong/hopthu/:id'">
                <v-btn tile color="success" small>
                  <v-icon left small> mdi-mail </v-icon>
                  Xem tất cả
                </v-btn>
              </router-link>
            </v-card-title>
            <v-card-text
              class="pa-6 pt-0 pb-2"
              v-if="tableData && tableData.length"
            >
              <v-list two-line style="height: 245px">
                <v-list-item-group active-class="primary--text">
                  <template v-for="(item, index) in tableData">
                    <v-list-item :key="item.id" @click="viewTruyenThong(item)">
                      <v-list-item-avatar color="indigo">
                        <img
                          v-if="item.nguoi_gui && item.nguoi_gui.url_image"
                          :src="imageEndpoint + item.nguoi_gui.url_image"
                          alt="ManhLe"
                        />
                        <span
                          style="color: white"
                          v-else-if="item.nguoi_gui && item.nguoi_gui.name"
                          >{{
                            item.nguoi_gui.name.charAt(0).toUpperCase()
                          }}</span
                        >
                        <v-icon v-else dark>mdi-account</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <div style="font-size: 18px; font-weight: 400">
                            {{ item.chu_de }}
                          </div>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="item.nhom">
                            Đến:
                            <span class="text--primary pl-1"
                              >{{ item.nhom }} <i> (Nhóm)</i>
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !item.nhom && item.nguoi_nhans.length < 4
                            "
                          >
                            Đến:
                            <span
                              class="text--primary pl-1"
                              v-for="nguoiNhan in item.nguoi_nhans"
                              :key="nguoiNhan.id"
                            >
                              {{ nguoiNhan.name }},
                            </span>
                          </div>
                          <div v-else>
                            Đến:
                            <span class="text--primary pl-1"
                              >{{ item.nguoi_nhans[0].name }},
                            </span>
                            <span class="text--primary pl-1"
                              >{{ item.nguoi_nhans[1].name }},
                            </span>
                            <span class="text--primary pl-1"
                              ><i
                                >và {{ item.nguoi_nhans.length - 2 }} người
                                khác</i
                              >
                            </span>
                          </div>
                        </v-list-item-subtitle>
                        <!-- <v-list-item-subtitle>
                      {{
                        item.noi_dung.length > 30
                          ? String(item.noi_dung).substr(0, 30) + "..."
                          : item.noi_dung
                      }}
                    </v-list-item-subtitle> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="item.thoi_gian"
                        ></v-list-item-action-text>

                        <v-chip
                          class="mt-2"
                          color="red"
                          text-color="white"
                          small
                        >
                          {{ item.so_binh_luan }}
                          Bình luận
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      :key="item.id + '-' + index"
                      :inset="true"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
              <div class="mt-6">
                <v-pagination
                  circle
                  v-model="page"
                  :length="pageCount"
                  @input="changePage"
                  :total-visible="6"
                  >></v-pagination
                >
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-layout
                style="height: 300px"
                align-center
                justify-center
                column
              >
                <v-icon size="80" color="pink"> mdi-email-open</v-icon>
                <div style="color: black; font-size: 16px">
                  Bạn chưa có thư truyền thông
                </div>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import pic1 from "./image/01.svg";
import pic2 from "./image/02.svg";
import pic3 from "./image/03.svg";
import pic4 from "./image/04.svg";
import pic5 from "./image/05.svg";
import pic6 from "./image/06.svg";
import dashboardLogo from "../../assets/images/dashboard.svg";
import mock from "./mock";
import Trend from "vuetrend";
import ApexChart from "vue-apexcharts";
import TrackingMap from "./map";
import NhanVienPic from "../../assets/images/nhanvien.svg";
import { getThuCaNhan } from "@/api/guithu";
import { getSoNhanSu } from "@/api/phongban";
import { getChamCongTodayDashboard } from "@/api/chamcong";
import { soNhanVienTheoDoTuoi, soNhanVienTheoGioiTinh } from "@/api/nhanvien";

export default {
  name: "Dashboard",
  components: {
    Trend,
    ApexChart,
    TrackingMap,
  },
  data() {
    return {
      pic: [pic1, pic2, pic3, pic4, pic5, pic6],
      NhanVienPic,
      dashboardLogo,
      picRandom: Math.floor(Math.random() * 6),
      mock,
      apexLoading: false,
      value: this.getRandomInt(10, 90),
      value2: this.getRandomInt(10, 90),
      mainApexAreaSelect: "Daily",
      ages: [],
      imageEndpoint: process.env.VUE_APP_BASE,
      page: 1,
      pageCount: 1,
      tableData: [],
      itemsPerPage: 3,
      phongBans: [],
      tongNhanSu: 0,
      hoatDong: 0,
      nghiViec: 0,
      options: {},
      series: [],
      tyTrongFilter: 1,
      checkingCount: {},
      showFullTyTrong: false,
      optionsDoTuoi: {},
      seriesDoTuoi: [],
      optionsGioiTinh: {},
      seriesGioiTinh: [],
    };
  },
  computed: {
    USER() {
      return this.$store.state.User.me;
    },
  },
  methods: {
    getRandomDataForTrends() {
      const arr = [];
      for (let i = 0; i < 12; i += 1) {
        arr.push(Math.random().toFixed(1) * 10);
      }
      return arr;
    },
    async getTyTrongTheoTuoi() {
      let data = await soNhanVienTheoDoTuoi();
      let doTuoiLable = Object.keys(data);
      let doTuoiNumber = Object.values(data);
      this.seriesDoTuoi = [...doTuoiNumber];
      doTuoiLable.forEach(function (part, index, theArray) {
        if (theArray[index] == "khong_xac_dinh") {
          theArray[index] = "Không xác định";
        }
        if (theArray[index] == "duoi_30") {
          theArray[index] = "Dưới 30 tuổi";
        }
        if (theArray[index] == "tu_30_den_40") {
          theArray[index] = "Từ 30 đến 40 tuổi";
        }
        if (theArray[index] == "tu_41_den_50") {
          theArray[index] = "Từ 41 đến 50 tuổi";
        }
        if (theArray[index] == "tren_50") {
          theArray[index] = "Trên 50 tuổi";
        }
      });
      this.optionsDoTuoi = {
        labels: doTuoiLable,
        series: doTuoiNumber,
        fill: {
          opacity: 1,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    async getTyTrongGioiTinh() {
      let data = await soNhanVienTheoGioiTinh();
      let gioiTinhLable = ["Nam", "Nữ"];
      let gioiTinhNumber = Object.values(data);
      this.seriesGioiTinh = [...gioiTinhNumber];

      this.optionsGioiTinh = {
        labels: gioiTinhLable,
        series: gioiTinhNumber,
        fill: {
          opacity: 1,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    async getDanhSachPhongBan() {
      let data = await getSoNhanSu();
      this.phongBans = data.phong_ban;
      this.tongNhanSu = data.tong_nhan_su;
      this.hoatDong = data.hoat_dong;
      this.nghiViec = data.nghi_viec;
      let nhanSuLable = [];
      let nhanSuNumber = [];
      this.phongBans.forEach((el) => {
        nhanSuLable.push(el.name);
        nhanSuNumber.push(el.nhan_su);
      });
      this.series = [...nhanSuNumber];
      this.options = {
        labels: nhanSuLable,
        series: nhanSuNumber,
        fill: {
          opacity: 1,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    async soLuongChamCong() {
      let data = await getChamCongTodayDashboard();
      this.checkingCount = data;
    },
    async getTruyenThong() {
      this.loading = true;
      let data = await getThuCaNhan({
        page: this.page,
        perPage: this.itemsPerPage,
        search: this.search,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.meta.last_page;
    },
    viewTruyenThong(data) {
      this.$router.push("/truyenthong/hopthu/" + data.id);
    },
    changePage(val) {
      this.page = val;
      this.getTruyenThong();
    },
    generatePieSeries() {
      let series = [];

      for (let i = 0; i < 4; i++) {
        let y = Math.floor(Math.random() * (500 - 100 + 100)) + 100;
        series.push(y);
      }
      return series;
    },
    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
  },
  mounted() {
    this.getTruyenThong();
    this.getDanhSachPhongBan();
    this.soLuongChamCong();
    this.getTyTrongTheoTuoi();
    this.getTyTrongGioiTinh();
    setTimeout(() => {
      this.apexLoading = true;
    });
  },
};
</script>

<style src="./Dashboard.scss" lang="scss" />

<style scoped>
.so-nhan-su {
  overflow: hidden;
}
.so-nhan-su:hover {
  overflow: auto;
}

/*Scroll bar
  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>