import request from "../utils/request";

export function getList(params) {
  return request({
    url: "checking",
    method: "get",
    params,
  });
}

export function getChiTiet(params) {
  return request({
    url: "showchecking",
    method: "get",
    params,
  });
}

export function capNhatTinhHopLe(data) {
  return request({
    url: "capnhattrangthai",
    method: "put",
    data,
  });
}

export function getListCaNhan(params) {
  return request({
    url: "chamcongcanhan",
    method: "get",
    params,
  });
}

export function soNgayChamCong(params) {
  return request({
    url: "ngaychamcong",
    method: "get",
    params,
  });
}

export function exportChamCong(params = {}){
  return request({
    url:"checking-export",
    method:"get",
    params: params,
    responseType: 'blob',
  })
}
export function exportChamCongMonth(params = {}){
  return request({
    url:"export-cham-cong-month",
    method:"get",
    params: params,
    responseType: 'blob',
    timeout: 60000
  })
}
export function exportChuaChamCong(params = {}){
  return request({
    url:"not-checking-export",
    method:"get",
    params: params,
    responseType: 'blob',
  })
}

export function exportError(params = {}){
  return request({
    url:"checking-error-export",
    method:"get",
    params: params,
    responseType: 'blob',
  })
}

export function getChamCongToday() {
  return request({
    url: "checkingtoday",
    method: "get",
  });
}
export function getChamCongTodayDashboard() {
  return request({
    url: "checkingdashboard",
    method: "get",
  });
}

export function duyetChamCong(data) {
  return request({
    url: "duyetchamcong",
    method: "post",
    data
  });
}