import "vue-toastification/dist/index.css";

import * as VueGoogleMaps from "vue2-google-maps";

import App from "./App.vue";
import CKEditor from "ckeditor4-vue";
import CustomTable from "./components/custom-table.vue";
import DatetimePicker from "vuetify-datetime-picker";
import ListLayout from "./components/Layout/ListLayout.vue";
import Toast from "vue-toastification";
import Vue from "vue";
import VueRouter from "vue-router";
import confirmBoxPlugin from "./plugins/confirmBox";
import router from "./router/permission";
import store from "./store/index";
import vuetify from "./plugins/vuetify";

Vue.use(CKEditor);
Vue.use(DatetimePicker);
Vue.use(confirmBoxPlugin, { store });
Vue.use(Toast);
Vue.use(VueRouter);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCRlcMGhWz1WPJfGCkzfbtKV7BfT60Qu5w",
  },
});

Vue.config.productionTip = false;

Vue.component("ListLayout", ListLayout);
Vue.component("CustomTable", CustomTable);

new Vue({
  el: "#app",
  router,
  vuetify,
  render: (h) => h(App),
  store,
});
