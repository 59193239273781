import request from "../utils/request";
export function getNhanVien(params) {
  return request({
    url: "nhanvien",
    method: "get",
    params,
  });
}

export function addNhanVien(data) {
  return request({
    url: "nhanvien",
    method: "post",
    data,
  });
}

export function editNhanVien(data) {
  return request({
    url: "nhanvien",
    method: "put",
    data,
  });
}

export function showNhanVien(params) {
  return request({
    url: "showNhanVien",
    method: "get",
    params,
  });
}

export function nghiViec(data) {
  return request({
    url: "nghiviec",
    method: "put",
    data,
  });
}

export function exportNhanVien(params = {}) {
  return request({
    url: "exportnhanvien",
    method: "get",
    params: params,
    responseType: "blob",
  });
}

export function danhGiaNhanVien(data) {
  return request({
    url: "danhgia",
    method: "post",
    data,
  });
}
export function getDanhGia(params) {
  return request({
    url: "danhgia",
    method: "get",
    params,
  });
}

export function soNhanVienTheoDoTuoi() {
  return request({
    url: "tytrongdotuoi",
    method: "get",
  });
}
export function soNhanVienTheoGioiTinh() {
  return request({
    url: "tytronggioitinh",
    method: "get",
  });
}
export function soNhanVienTheoKhuVuc() {
  return request({
    url: "tytrongkhuvuc",
    method: "get",
  });
}

export function soNhanVienTheoNamLamViec() {
  return request({
    url: "tytrongnamlamviec",
    method: "get",
  });
}
export function soNhanVienTheoTrinhDo() {
  return request({
    url: "tytrongtrinhdo",
    method: "get",
  });
}

export function listDanhGia(params) {
  return request({
    url: "listdanhgia",
    method: "get",
    params,
  });
}

export function importDanhGia(data) {
  return request({
    url: "/importdanhgia",
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data,
  });
}
export function getHanhVi(params) {
  return request({
    url: "hanhvi",
    method: "get",
    params,
  });
}