export default {
  light: {
    primary: "#4285f4",
    secondary: "#0747a6",
    error: "#FF4081",
    warning: "#fbbc04",
    success: "#34a853",
    info: "#9013FE",
    textColor: "#B9B9B9",
    iconColor: "#FFFFFF",
    grayBold: "#4a4a4a",
  },
};
export const projects = [
  {
    name: "Quản lý nhân sự",
    path: "/",
    avatar_url: "/icon/human-resource.png",
  },
  {
    name: "Quản lý sản xuất",
    path: "https://mauxanhcuocsong.vn/",
    avatar_url: "/icon/inventory-control.png",
  },
  {
    name: "Quản lý kinh doanh",
    path: "https://kinhdoanh.mauxanhcuocsong.vn",
    avatar_url: "/icon/sale.png",
  },
];

export const project = {
  name: process.env.VUE_APP_TITLE,
  path: "/",
  avatar_url: "/icon/module/module-human-resource.png",
};
