<template>
  <div></div>
</template>
<script>
import { oauthCallBack } from "@/api/user";
import store from "../../store/index";

export default {
  async beforeRouteEnter(to, from, next) {
    const { code } = to.query;
    const res = await oauthCallBack({
      code: code,
      redirect_uri: window.location.origin + "/oauth-callback",
    });
    if (res.access_token) {
      await store.dispatch("User/loginOauth", res.access_token);
      next({path: "/dashboard"})
    }
  },
};
</script>