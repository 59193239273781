<template>
  <v-avatar
    v-if="!data[field]"
    :color="currentColor"
    :size="size"
    v-bind="$attrs"
  >
    <span :class="classText" :style="{ fontSize: fontSize + 'px' }">{{
      data.name | shortName
    }}</span>
  </v-avatar>
  <v-avatar v-else :size="size" v-bind="$attrs">
    <img :alt="field" :src="data[field]" />
  </v-avatar>
</template>

<script>
export function getRandomColor() {
  var colors = ["#ffebee", "#e8eaf6", "#f3e5f5", "#eee", "#e3f2fd", "#e3f2fd"];
  const index = Math.floor(Math.random() * 6);
  return colors[index];
}
export default {
  props: {
    data: {},
    size: { type: [Number, String], default: 48 },
    field: { type: String, default: "avatar_url" },
    color: { type: String, default: "" },
    classText: { type: String, default: null },
  },
  data: () => ({}),
  computed: {
    fontSize() {
      return Math.round(this.size / 3, 0);
    },
    currentColor() {
      return this.color || getRandomColor();
    },
  },
  filters: {
    shortName(str = "") {
      if (!str) return "";
      str = str.match(/\b(\w)/g).join("");
      return str.substring(0, 1).toUpperCase();
    },
  },
};
</script>

<style></style>
